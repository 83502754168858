
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IList {
  accountName: string;
  accountNature: string;
  totalExpense: number;
  totalRevenue: number;
}

@Options({
  title: 'Statement of Income',
  components: {},
})

export default class IncomeStatement extends mixins(UtilityOptions) {
  private lists: IList []  = [];
  private reportService;
  private resultTitle = "";
  private storeName = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Statement of Income", to: "income-statement-report" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    storeID: 0,
  };

  private brands:any = [];
  private sectors:any = [];
  private categories:any = [];
  private productTypes:any = [];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.incomeStatement(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.storeName = data.storeName;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  get allRevenueAccounts()
  {
    const a = this.lists.filter(e => e.accountNature == 'Revenue');
    return a;
  }
  
  get allExpenseAccounts()
  {
    const a = this.lists.filter(e => e.accountNature == 'Expense');
    return a;
  }

  get sumRevenue()
  {
    let total = 0;

    this.lists.forEach(e => {
      if(e.accountNature == 'Revenue')
      {
        total = total + Number(e.totalRevenue);
      }
    });
    return total;
  }
  
  get sumExpense()
  {
    let total = 0;

    this.lists.forEach(e => {
      if(e.accountNature == 'Expense')
      {
        total = total + Number(e.totalExpense);
      }
    });
    return total;
  } 
  
  get netProfit()
  {
    return this.sumRevenue - this.sumExpense;
  } 

  get currency() {
    return this.store.getters.getCurrency;
  }
}
