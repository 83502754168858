
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import AutoComplete from "primevue/autocomplete";


interface ITransaction {
  accountHead   : string;
  accountId     : number;
  amount        : string;
  bankId        : number;
  branchId      : number;
  createdAt     : string;
  createdBy     : number;
  description   : string;
  id            : number;
  receiptDate   : string;
  receiptNo     : string;
  status        : string;
  transactionId : number;
  transactionNo : string;
  type          : string;
  updatedAt     : string;
}

@Options({
  title: 'Statement of Bank Reconcile',
  components: {AutoComplete},
})

export default class BankReconciliation extends mixins(UtilityOptions) {
  private lists : ITransaction [] = [];
  private reportService;
  private resultTitle     = "";
  private beforeStatementAmount     = 0;
  private openingBalance     = 0;
  private openingBalanceDate = "";
  private beforeStatement = "";
  private endStatement = "";
  private storeName = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Statement of Bank Reconcile", to: "bank-reconciliation" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    filterType: "None",
    storeID: 0,
    bank: {},
  };

  private dateFilters = [
    {
      key: "None",
      value: "None" 
    },
    {
      key: "This Month",
      value: "this_month" 
    },
    {
      key: "Last Month",
      value: "last_month" 
    }
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];
  private banksList = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.bankList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
    });
  }
  
  bankList()
  {
    this.reportService.getAllBanks().then((res) => {
      this.banksList  = res.records;
      if(this.banksList != null)
      {
        this.searchFilters.bank = this.banksList[0];
        this.loadList();
      }  
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.bankStatement(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.storeName = data.storeName;
        this.lists = data.record;
        this.beforeStatement = data.beforeStatement;
        this.endStatement    = data.endStatement;
        this.beforeStatementAmount    = data.beforeStatementAmount;
        this.openingBalance        = data.openingBalance;
        this.openingBalanceDate    = data.openingBalanceDate;
        this.loading = false;  
      });
    this.productDialog = false;
  }

  get outStandingCheque()
  {
    return this.lists.filter(e => e.status == 'Outstanding');
  }

  get totalOutStanding()
  {
    let t = 0;
    this.outStandingCheque.forEach(e => {
      t = t + Number(e.amount);
    });
    return t;
  }
  
  get deductTransactions()
  {
    return this.lists.filter(e => (e.type == 'CHQ' && e.status == 'Active') || e.type == 'EXP' || e.type == 'FTR' || e.type == 'RFD' || e.type == 'PUR' || e.type == 'RFR');
  }

   get totalDeductTransactions()
  {
    let t = 0;
    this.deductTransactions.forEach(e => {
      t = t + Number(e.amount);
    });
    return t;
  }
  
  get addTransactions()
  {
    return this.lists.filter(e => e.type == 'DPT' || e.type == 'REF' || e.type == 'RPU' || e.type == 'INE' || e.type == 'TRN' || e.type == 'INV' || e.type == 'SLS');
  }

  get totalAddTransactions()
  {
    let t = 0;
    this.addTransactions.forEach(e => {
      t = t + Number(e.amount);
    });
    return t;
  }

  get totalBalanceAmount()
  {
    return this.openingBalance + (this.beforeStatementAmount-this.totalOutStanding-this.totalDeductTransactions+this.totalAddTransactions);
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
